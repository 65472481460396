<template>
  <Card title="任务配置">
    <a-form-model layout="inline" @keyup.enter.native="query" style="margin-bottom: 2vh">
      <a-row>
        <a-col :span="22">
          <a-form-model-item>
            <a-input v-model="form.name" placeholder="名称" />
          </a-form-model-item>
          <a-form-model-item>
            <a-select v-model="form.type" placeholder="类型" style="width: 150px">
              <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-radio-group v-model="form.status">
              <a-radio v-for="item in statusList" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button type="primary" @click="query">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-col>

        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button type="primary" @click="$router.push('/manage/map?page=temp&action=add')">新建</a-button>
          </div>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table bordered :dataSource="list" @change="onChange" :loading="loading" :pagination="{
      total,
      current,
      pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    }" rowKey="id">
      <a-table-column key="name" title="任务名称" data-index="name" />
      <a-table-column key="type" title="任务类型">
        <template slot-scope="text">
          <DataDictFinder dictType="taskType" :dictValue="String(text.type)" />
        </template>
      </a-table-column>

      <a-table-column key="exeMonths" title="执行月份" data-index="exeMonths" />
      <a-table-column key="exeWeek" title="执行星期" data-index="exeWeek" />
      <a-table-column key="exeDate" title="执行日期" data-index="exeDate" />

      <a-table-column key="status" title="状态" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="status" :dictValue="String(text.status)" />
        </template>
      </a-table-column>

      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text">
          <a-space>
            <a href="#" @click.prevent="
              $router.push('/manage/map?page=temp&action=edit&id=' + text.id)
              ">编辑</a>
            <a href="#" class="danger" @click.prevent="deleteText(text)">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </Card>
</template>

<script>
import { fetchList, remove } from "../api/temp";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("taskType");
    },
    statusList() {
      return this.findDataDict("status");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({ id: text.id }).then(() => {
            that.getList();
          });
        },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>