import request from '@/api/request'

export function fetchList(data) {
    return request({
        url: '/model-analysis/inspectTemplate/list',
        method: 'post',
        data,
    })
}

export function add(data) {
    return request({
        url: '/model-analysis/inspectTemplate/save',
        method: 'post',
        data,
    })
}

export function fetchDetail({ id }) {
    return request({
        url: '/model-analysis/inspectTemplate/queryDetail/' + id,
    })
}

export function edit(data) {
    return request({
        url: '/model-analysis/inspectTemplate/modify',
        method: 'post',
        data,
    })
}

export function remove({ id }) {
    return request({
        url: '/model-analysis/inspectTemplate/delete/' + id,
        method: 'post'
    })
}